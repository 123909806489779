<template>
  <div style="width: 100%;background-color: RGBA(250, 250, 250, 1);height: 100%!important;">
    <common-header></common-header>
    <div v-if="nowIndex==0" style="margin-top: 40px;">
      <div class="enterprise_cer_box">
        <div class="enterprise_cer">
          <div class="enterprise_cer_title">
                        <span style="font-size: 20px;
                        font-family: 'Microsoft YaHei';
                        color: rgb(51, 51, 51);">企业认证</span>
          </div>
          <div class="enterprise_cer_content">
            <div>
              <span>企业名称&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><Input v-model="name"
                                                                                                  style="width: 500px"/>
            </div>
            <div>
              <span>企业统一社会代码&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><Input
              v-model="creditCode" style="width: 500px"/>
            </div>
            <div>
              <span>上传营业执照&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                style="width: 500px;display: inline-block;text-align: left">请上传 <span style="color: #ff6a00">营业执照</span> 照片</span></span><br>
            </div>
            <div style="width: 150px;margin: 0 auto;height: 120px">
              <Upload
                :format="['jpg','png']"
                :max-size="1024"
                type="drag"
                :before-upload="(file) => handleUpload(file)"
                :show-upload-list="showUploadList"
                action="">
                <div style="padding: 0 0;position: relative">
                  <Icon type="ios-add-circle" size="36" color="#ff6a00"/>
                  <div style="    position: absolute;
    top: 45px;
    left: 20px;">
                    <p class="p_style">点击上传照片</p>
                    <p class="p_style p_small_style">支持jpg/png格式</p>
                    <p class="p_style p_small_style">不超过1m</p>
                  </div>

                </div>
              </Upload>
              <div style="margin-left: 50%;transform: translateX(-50%);width: 200px;text-align:center;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">{{file}}</div>
            </div>

            <div
              style="margin:0 auto;margin-top: 50px;width: 150px;height: 40px;background-color: #ff6a00;cursor: pointer;text-align: center;line-height: 40px;color: white;cursor: pointer"
              @click="nextStep()">下一步
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="nowIndex==1" style="background-color: RGBA(250, 250, 250, 1)">
      <div
        style="width: 1200px;margin:0 auto;height: 1115px;background-color: white;box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);margin-top: 100px">
        <div class="enterprise_cer_title"
             style="text-align: center;line-height: 89px;border-bottom: 1px solid rgba(240, 242, 246, 1)">
                        <span style="font-size: 20px;
                        font-family: Microsoft YaHei;
                        text-align: center;
                        color: rgb(51, 51, 51);">
                          企业认证
                        </span>
        </div>
        <div style="margin-bottom: 30px">
          <div style="width: 1030px;margin:0 auto;height: 60px;line-height: 60px">
            <div
              style="display: inline-block;background-color: rgba(255, 106, 0, 1);width: 3px;height: 12px;margin-right: 10px"></div>
            <span style="font-size: 18px;color: black">企业信息</span>
          </div>
          <div
            style="height: 273px;width: 1030px;margin: 0 auto;background-color: RGBA(250, 250, 250, 1);padding: 30px 40px"
            id="qyxx">
            <Row>
              <Col span="12" class="tag_div">
                <div>
                  <span class="tag_span">法定代表人&nbsp;&nbsp;&nbsp;</span> <span class="value_span"><input
                  v-model="productForm.legalRepresentative"/></span> <span class="correct" style="cursor: pointer;" @click="correct('法定代表人','legalRepresentative')">纠错</span>
                </div>
                <div>
                  <span class="tag_span">企业地址&nbsp;&nbsp;&nbsp;</span> <span class="value_span"><input
                  v-model="productForm.site"/></span> <span class="correct" style="cursor: pointer;" @click="correct('企业地址','site')">纠错</span>
                </div>
                <div>
                  <span class="tag_span">行政区划&nbsp;&nbsp;&nbsp;</span> <span class="value_span"><input
                  v-model="productForm.administrativeDivision"/></span> <span class="correct" style="cursor: pointer;" @click="correct('行政区划','administrativeDivision')">纠错</span>
                </div>
                <div>
                  <span class="tag_span">所属科技领域&nbsp;&nbsp;&nbsp;</span> <span class="value_span"><input
                  v-model="productForm.satTerritory"/></span> <span class="correct" style="cursor: pointer;" @click="correct('所属科技领域','satTerritory')">纠错</span>
                </div>
                <div>
                  <span class="tag_span">注册资本金&nbsp;&nbsp;&nbsp;</span> <span class="value_span"><input
                  v-model="productForm.registeredCapital"/></span> <span class="correct" style="cursor: pointer;" @click="correct('注册资本金','registeredCapital')">纠错</span>
                </div>
                <div>
                  <span class="tag_span">注册时间&nbsp;&nbsp;&nbsp;</span> <span class="value_span"><input
                  v-model="productForm.registerTime"/></span> <span class="correct" style="cursor: pointer;" @click="correct('注册时间','registerTime')">纠错</span>
                </div>
              </Col>
              <Col span="12" class="tag_div">
                <div>
                  <span class="tag_span">所属行业&nbsp;&nbsp;&nbsp;</span> <span class="value_span"><input
                  v-model="productForm.involved"/></span> <span class="correct" style="cursor: pointer;" @click="correct('所属行业','involved')">纠错</span>
                </div>
                <div>
                  <span class="tag_span">员工数量&nbsp;&nbsp;&nbsp;</span> <span class="value_span"><input
                  v-model="productForm.staffTotal"/></span> <span class="correct" style="cursor: pointer;" @click="correct('员工数量','staffTotal')">纠错</span>
                </div>
                <div>
                  <span class="tag_span">企业类型&nbsp;&nbsp;&nbsp;</span> <span class="value_span"><input
                  v-model="productForm.enterpriseType"/></span> <span class="correct" style="cursor: pointer;" @click="correct('企业类型','enterpriseType')">纠错</span>
                </div>
                <div>
                  <span class="tag_span">营业期限&nbsp;&nbsp;&nbsp;</span> <span class="value_span"><input
                  v-model="productForm.businessTerm"/></span> <span class="correct" style="cursor: pointer;" @click="correct('营业期限','businessTerm')">纠错</span>
                </div>
                <div>
                  <span class="tag_span">经营范围&nbsp;&nbsp;&nbsp;</span> <span class="value_span"><input
                  v-model="productForm.businessScope"/></span> <span class="correct" style="cursor: pointer;" @click="correct('经营范围','businessScope')">纠错</span>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div style="margin-bottom: 30px">
          <div style="width: 1030px;margin:0 auto;height: 60px;line-height: 60px">
            <div
              style="display: inline-block;background-color: rgba(255, 106, 0, 1);width: 3px;height: 12px;margin-right: 10px"></div>
            <span style="font-size: 18px;color: black">产品信息</span>
          </div>
          <div
            style="height: 425px;width: 1030px;margin: 0 auto;background-color: RGBA(250, 250, 250, 1);padding-left: 50px;padding-top: 60px">
            <Form :model="productForm" :label-width="150" :rules="requireRule">
              <FormItem label="主要产品名称" prop="productName">
                <Input v-model="productForm.productName" placeholder='主要产品名称（多个以";"分隔）' style="width: 504px"></Input>
              </FormItem>
              <FormItem label="主要产品领域">
                <Input v-model="productForm.mptName" placeholder='主要产品领域（多个以";"分隔）' style="width: 504px"></Input>
              </FormItem>
              <FormItem label="主要产品标签" prop="productLabel">
                <Input v-model="productForm.productLabel" placeholder='主要产品标签（多个以";"分隔）' style="width: 504px"></Input>
              </FormItem>
              <FormItem label="主要产品描述">
                <Input v-model="productForm.productDescribe" placeholder='主要产品描述' style="width: 504px" type="textarea"
                       :autosize="{minRows: 5,maxRows: 8}"></Input>
              </FormItem>
            </Form>
          </div>
        </div>
        <div
          style="margin:0 auto;margin-top: 50px;width: 150px;height: 40px;background-color: #ff6a00;cursor: pointer;text-align: center;line-height: 40px;color: white;cursor: pointer"
          @click="goRenZheng">提交认证
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    isCorrectPassword,
    isPhoneNumber,
    successToast,
    warnToast,
    errorToast
  } from "@/plugins/tools/util";
  import {uploadImg, getBasicDetail, saveInfo} from "@/plugins/api/EnterpriseCertification";
  import {mapMutations, mapActions,mapGetters} from 'vuex'

  export default {
    name: "EnterpriseCertification",
    mounted() {
      if(this.enterpriseState === 'EXAMINING' || this.enterpriseState === 'CERTIFIED'){
        this.$router.push({
          path:'/'
        })
      }
    },
    data() {
      return {
        showUploadList: false,
        nowIndex:0,
        name: "",
        creditCode: "",
        file: "",
        enterpriseLicenseUrl:"",
        productForm: {
          legalRepresentative: '',
          involved: '',
          site: '',
          enterpriseLicenseUrl:'',
          staffTotal: '',
          administrativeDivision: '',
          enterpriseType: '',
          satTerritory: '',
          businessTerm: '',
          registeredCapital: '',
          businessScope: '',
          registerTime: '',
          productName: '',
          mptName: '',
          productLabel: '',
          productDescribe: ''
        },
        requireRule:{
          productName: [
            { required: true ,
              message: '产品名称必填',}
          ],
          productLabel: [
            { required: true, message: '标签名必填', trigger: 'blur' }
          ],
        }

      }
    },

    computed:{
      ...mapGetters({'enterpriseState':'userStore/enterpriseState'})
    },
    methods: {
      ...mapMutations({
        setUserToken: 'userStore/setUserToken',
        setUserName: 'userStore/setUserName',
        setUserAccountType: 'userStore/setUserAccountType',
        setUserMbrMemberId: 'userStore/setUserMbrMemberId',
        setUserId: 'userStore/setUserId',
        setUserEnterpriseState: 'userStore/setUserEnterpriseState'
      }),
      correct(cName,eName){
        this.productForm[eName] = "";
      },
      async nextStep() {
        let data = {
          // name: this.name,
          name: this.creditCode,
          // enterpriseLicenseUrl:this.enterpriseLicenseUrl
        };
        for (let key in data) {
          if (data[key] === '') {
            warnToast("请将信息填写完成")
            return
          }
        };
        let json = await getBasicDetail(data);
        if (json.code == 0) {
          for(let key in json.result){
            this.productForm[key] = json.result[key]
          };
          this.nowIndex = 1;
        } else {
          errorToast(json.message)
        }
      },
      async handleUpload(file) {
        this.file = file.name;
        const FileExt = this.file.replace(/.+\./, "");//取得文件的后缀名
        if (FileExt == 'png' || FileExt == 'jpg') {
          const reader = new FileReader();
          //将文件读取为 DataURL 以data:开头的字符串
          let code = '';
          let _that = this;
          reader.readAsDataURL(file);
          reader.onload = e => {
            // 读取到的图片base64 数据编码 将此编码字符串传给后台即可
            code = e.target.result;

            let data = {
              imageBase64: code,
              dataType: 0
            };
            uploadImg(data).then(res => {
              if (res.code == 200) {
                _that.enterpriseLicenseUrl = res.data

                successToast("上传成功")
              } else {
                warnToast(res.message)
              }
            })
          }

        } else {
          warnToast('只支持jpg和png')
        }

      },
      async goRenZheng() {
        this.productForm.enterpriseName = this.name;
        this.productForm.creditCode = this.creditCode;
        this.productForm.enterpriseLicenseUrl = this.enterpriseLicenseUrl;
        if(this.productForm.productLabel== ""){
          warnToast("请将产品标签填写完成")
          return;
        }
        if(this.productForm.productName== ""){
          warnToast("请将产品名称填写完成")
          return;
        }
        let json = await saveInfo(this.productForm)
        if (json.code == 0) {
          successToast("等待审核")
          this.setUserEnterpriseState('EXAMINING')
          this.$router.push({
            path: '/enterprise/enterprise-management-center',
            query: {
              id: '0'
            }
          })
          scrollTo(0,0)
        } else {
          errorToast(json.message)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  /deep/ .tag_div .ivu-input{
    background-color: rgb(250, 250, 250) !important;
    border: none !important;
    outline: none !important;
  }

  /deep/ .tag_div>.ivu-input:focus {
    border-color: #57a3f3;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(45, 40, 240, .2) !important;
    box-shadow: 0 0 0 2px rgba(45, 40, 220, .2)!important;
  }
  .tag_div > div {
    height: 40px;
  }

  .tag_span {
    display: inline-block;
    text-align: right;
    width: 100px;
    margin-right: 30px;
  }

  .value_span {
    display: inline-block;
    margin-right: 30px;
  }
  input:focus {
    border: none!important;
    outline: none!important;
  }
  input {
    border: none!important;
    outline: none!important;
    background-color: rgb(250, 250, 250) !important;

  }
  #app > div {
    height: auto !important;
    padding-bottom: 90px;
  }

  .p_style {
    height: 16px;
    line-height: 16px;
  }

  .p_small_style {
    font-size: 14px;
    font-family: Microsoft YaHei;
    color: rgb(153, 153, 153);
  }

  .upload_style {
    height: 20%;
    width: 20%;
  }

  @media screen and (max-width: 1300px) {
    .header_text {
      display: none !important;
    }
    .common_header {
      display: flex;
      justify-content: center;
    }
  }

  .enterprise_cer_box {
    height: calc(100% - 41px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .enterprise_cer {
      background-color: white;
      height: 80%;
      width: 1200px;
      box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
      min-height: 520px;

      .enterprise_cer_title {
        height: 89px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgb(245, 245, 245);
      }

      .enterprise_cer_content {
        width: 800px;
        margin: 3% auto;
        height: 80%;
        text-align: right;
        overflow: hidden;
        position: relative;
      }
    }

  }

  .enterprise_cer_content > div {
    margin-bottom: 1%;
  }

  .enterprise_cer_content span {
    color: rgb(102, 102, 102);
    font-size: 14px;
    vertical-align: top;
  }

  .enterprise_cer_content > div {
    height: 60px;
    line-height: 60px;
  }

  .common_header {
    height: 41px;
    padding: 14px 0;
    line-height: 41px;
    background-color: rgb(245, 245, 245);
    width: 100%;
    min-width: 1200px;

  }

  .header_text {
    float: left;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 4%;
  }

  .tab_login_or_not {
    padding-right: 4%;
    height: 13px;
    line-height: 13px;
    font-size: 13px;
    float: right;

    span {
      display: inline-block;
      padding-left: 15px;
      margin-left: 15px;
      height: 13px;
      border-left: 1px solid black;
      cursor: pointer;
    }
  }

  .tab_login_or_not span:nth-child(1), .tab_login_or_not span:nth-child(2) {
    border: none;
  }

  .correct {
    color: rgba(255, 106, 0, 1);
  }
</style>
