import {get,post} from "../http/http";
import {certificationUrl, qxtUrl} from "../http/baseUrl";


// 上传图片
export const uploadImg = (data) => {
  return post(`${qxtUrl}/upload/base64Img`,data)
}

// 企查查
export const getBasicDetail = (data) => {
  return get(`${certificationUrl}/qcc/getBasicDetail`,data)
}


// 提交
export const saveInfo = (data) => {
  return post(`${certificationUrl}/enterprise/saveAuthInfo`,data)
}
